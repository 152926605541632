import React from 'react';
import { Button, Avatar, Grid, Typography, Container, LinearProgress } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';
import SnackbarAction from '../common/SnackbarAction';
import { mutation } from '../../graphql/client';
import { CREATE_TEAM } from '../../graphql/mutations';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Values {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  team: string;
}

type ResetFunction = () => void;

interface FormikFunctions {
  resetForm: ResetFunction;
}

export default function CreateTenant(): React.ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: Values, formFunctions: FormikFunctions) => {
    try {
      await mutation(CREATE_TEAM, {
        newTeam: {
          name: values.team,
          companyName: values.companyName,
          ownerEmail: values.email,
          password: values.password,
          ownerFirstName: values.firstName,
          ownerLastName: values.lastName,
        },
      });
      enqueueSnackbar(`Tenant ${values.team} created successfully!`, {
        variant: 'success',
        autoHideDuration: 10000,
        action: SnackbarAction,
      });
      formFunctions.resetForm();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Error creating new tenant', {
        variant: 'error',
        autoHideDuration: 10000,
        action: SnackbarAction,
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <GroupIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create a new Tenant
        </Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            companyName: '',
            jobTitle: '',
            team: '',
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }

            if (!values.password) {
              errors.password = 'Required';
            } else if (values.password.length < 8) {
              errors.password = 'Password should be at least 8 characters';
            } else if (values.password.length > 99) {
              errors.password = 'Password should not be greater than 99 characters';
            } else if (values.password.toLowerCase() === values.password) {
              errors.password = 'Password should have at least one uppercase';
            } else if (values.password.toUpperCase() === values.password) {
              errors.password = 'Password should have at least one lowercase';
            } else if (!/\d/.test(values.password)) {
              errors.password = 'Password should have at least one number';
            } else if (!/[!@#$%^&]/.test(values.password)) {
              errors.password = 'Password should have at least one special character. Ex: !@#$%^&';
            } else if (/\s/g.test(values.password)) {
              errors.password = 'Password should not have whitespace';
            }

            if (!values.firstName) {
              errors.firstName = 'Required';
            }

            if (!values.lastName) {
              errors.lastName = 'Required';
            }

            if (!values.companyName) {
              errors.companyName = 'Required';
            }

            if (!values.team) {
              errors.team = 'Required';
            }

            if (!values.jobTitle) {
              errors.jobTitle = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values, { resetForm });
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field component={TextField} variant="outlined" fullWidth label="Company" name="companyName" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field component={TextField} variant="outlined" fullWidth label="Team" name="team" />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="outlined" fullWidth label="Work Email" name="email" />
                </Grid>
                <Grid item xs={12}>
                  <Field component={TextField} variant="outlined" fullWidth label="Job Title" name="jobTitle" />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                  />
                </Grid>
              </Grid>

              {isSubmitting && <LinearProgress />}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                className={classes.submit}
              >
                Create Tenant
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
