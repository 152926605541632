import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { useQuery } from '../../graphql/client';
import { GET_TEAMS } from '../../graphql/queries';

export interface Team {
  id: string;
  name: string;
  ownerId: string;
  companyName: string;
  createdAt: number;
  memberCount: number;
}

export interface TeamsPage {
  teams: Team[];
  nextToken: string;
}

export interface QueryResult {
  getTeams: TeamsPage;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Tenants(): React.ReactElement {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [index, setIndex] = React.useState<Map<number, string>>(new Map());
  const [count, setCount] = React.useState(0);
  const { loading, data, refetch } = useQuery<QueryResult>(GET_TEAMS, {
    limit: rowsPerPage,
  });

  const handleRefresh = async () => {
    refetch();
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [rowsPerPage]);

  useEffect(() => {
    if (data && data.getTeams) {
      setCount(() => {
        let newCount = page * rowsPerPage + data?.getTeams.teams.length;

        if (data.getTeams.nextToken) {
          newCount = newCount + 1;
        }

        return newCount;
      });
    }
  }, [data, rowsPerPage, page]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    const vars: { [key: string]: string } = {};

    if (newPage < page && newPage !== 0) {
      const token = index.get(newPage);
      if (token) {
        vars['nextToken'] = token;
      }
    } else if (newPage !== 0) {
      vars['nextToken'] = data?.getTeams.nextToken;

      setIndex((prevIndex) => {
        const newIndex = new Map(prevIndex);
        newIndex.set(newPage, data?.getTeams.nextToken);

        return newIndex;
      });
    }
    setPage(newPage);
    refetch(vars);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCount(0);
  };

  return (
    <Card>
      <CardHeader
        title="Tenants"
        action={
          <IconButton aria-label="settings" onClick={handleRefresh}>
            <Refresh />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">NAME</TableCell>
                  <TableCell align="center">COMPANY</TableCell>
                  <TableCell align="center">OWNER</TableCell>
                  <TableCell align="center">MEMBERS</TableCell>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">CREATED ON</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.getTeams ? (
                  data.getTeams.teams.map((team) => (
                    <TableRow key={team.id}>
                      <TableCell align="center">{team.name}</TableCell>
                      <TableCell align="center">{team.companyName}</TableCell>
                      <TableCell align="center">{team.ownerId}</TableCell>
                      <TableCell align="center">{team.memberCount}</TableCell>
                      <TableCell align="center">{team.id}</TableCell>
                      <TableCell align="center">{team.createdAt}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <p>No recordings yet</p>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 50]}
                    colSpan={9}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
}
