import React, { useEffect, useState } from 'react';
import { Button, CssBaseline, Drawer, List } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonIcon from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import logo from '../../images/Logo.svg';
import { useUser } from '../../context/userContext';
import ProfileAvatar from './ProfileAvatar';
import InviteForm from '../invite/InviteForm';
import NavItem from './NavItem';
import { Group, GroupAdd } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    logo: {
      maxWidth: 80,
      marginRight: '0px',
    },
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  }),
);

interface Props {
  children: React.ReactNode;
}

interface FullName {
  firstName: string;
  lastName: string;
}

export default function MenuAppBar(props: Props): React.ReactElement {
  const classes = useStyles();
  const [openInvite, setOpenInvite] = useState(false);

  const { user, logout } = useUser();
  const [fullName, setFullName] = useState<FullName>({ firstName: '', lastName: '' });

  useEffect(() => {
    if (user.userAttributes) {
      setFullName({ firstName: user.userAttributes.given_name, lastName: user.userAttributes.family_name });
    }
  }, [user.userAttributes]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    if (logout) {
      logout();
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <InviteForm open={openInvite} handleClose={() => setOpenInvite(false)} />
      <AppBar color="inherit" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="logo" className={classes.logo} />
          <Typography variant="h6" className={classes.title}></Typography>
          {user.isLoaded && user.userAttributes && (
            <>
              {user.groups.indexOf('Admin') > -1 && (
                <Button color="inherit" onClick={() => setOpenInvite(true)}>
                  Invite
                </Button>
              )}
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <ProfileAvatar firstName={fullName.firstName} lastName={fullName.lastName} imageUrl="ad" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {user.isLoaded && user.userAttributes && (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              {user.groups.indexOf('User') > -1 && <NavItem href="/home" title="Home" icon={HomeIcon} />}
              {user.groups.indexOf('User') > -1 && <NavItem href="/call" title="Start Call" icon={AddIcCallIcon} />}
              {user.groups.indexOf('Admin') > -1 && (
                <NavItem href="/admin" title="Team Dashboard" icon={BarChartIcon} />
              )}
              <NavItem href="/profile" title="Account" icon={PersonIcon} />
              {user.groups.indexOf('SuperUser') > -1 && <NavItem href="/tenants" title="Tenants" icon={Group} />}
              {user.groups.indexOf('SuperUser') > -1 && (
                <NavItem href="/create-tenant" title="Add Tenant" icon={GroupAdd} />
              )}
            </List>
          </div>
        </Drawer>
      )}
      <main className={classes.content}>
        <Toolbar />
        {props.children}
      </main>
    </div>
  );
}
