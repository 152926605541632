import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useUser } from '../../../context/userContext';
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';
import SnackbarAction from '../../common/SnackbarAction';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Values {
  email: string;
  password: string;
}

export default function Login(): React.ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const locationState: any = location.state || { from: { pathname: '/home' } };
  const { enqueueSnackbar } = useSnackbar();

  const { login } = useUser();

  async function handleSignIn(values: Values) {
    try {
      if (login) {
        await login(values.email, values.password, true);
        history.replace(locationState.from);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 5000, action: SnackbarAction });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }

            if (!values.password) {
              errors.password = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            await handleSignIn(values);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.form} noValidate>
              <Field
                component={TextField}
                name="email"
                type="email"
                label="Email Address"
                fullWidth
                variant="outlined"
                margin="normal"
                autoFocus
              />

              <Field
                component={TextField}
                type="password"
                label="Password"
                name="password"
                variant="outlined"
                margin="normal"
                fullWidth
                autoComplete="current-password"
              />

              {isSubmitting && <LinearProgress />}
              <Button
                variant="contained"
                fullWidth
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                className={classes.submit}
                type="submit"
              >
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
        <Grid container>
          <Grid item xs>
            <Link variant="body2" component={RouterLink} to="/auth/reset">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link variant="body2" component={RouterLink} to="/auth/signup">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
