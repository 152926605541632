import React from 'react';
import { Location } from 'history';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUser } from '../../context/userContext';

export interface LocationState {
  from: Location;
}

export default function PrivateRoute({ children, ...rest }: RouteProps): React.ReactElement {
  const { user } = useUser();

  return (
    <>
      {!user.isLoaded ? (
        <></>
      ) : (
        <Route
          {...rest}
          render={({ location }) =>
            user.user && !user.challengeName ? (
              children
            ) : user.challengeName === 'NEW_PASSWORD_REQUIRED' ? (
              <Redirect
                to={{
                  pathname: '/auth/setup',
                  state: { from: location },
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/auth/login',
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
}
