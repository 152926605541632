import gql from 'graphql-tag';

export const LIST_USER_SESSIONS = gql`
  query listUserSessions($limit: Int, $nextToken: String) {
    listUserSessions(limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        score
        objective
        leadType
        buyer {
          email
          givenName
          familyName
        }
        recordingDuration
        recordingUrl
      }
      nextToken
    }
  }
`;

export const LIST_TEAM_SESSIONS = gql`
  query LIST_TEAM_SESSIONS($limit: Int) {
    listTeamSessions(limit: $limit) {
      items {
        createdAt
        id
        leadType
        objective
        recordingDuration
        recordingUrl
        score
        status
        teamName
        buyer {
          familyName
          givenName
          email
        }
        seller {
          familyName
          givenName
          email
        }
      }
    }
  }
`;

export const LIST_USERS = gql`
  query LIST_USERS($limit: Int) {
    getUsers(limit: $limit) {
      users {
        email
        familyName
        givenName
        jobTitle
        status
        teamName
        updatedAt
        createdAt
        companyName
        averageScore
      }
    }
  }
`;

export const GET_MY_PROFILE = gql`
  query getMyProfile {
    getMyProfile {
      averageScore
      companyName
      createdAt
      email
      familyName
      givenName
      jobTitle
      status
      teamName
      updatedAt
    }
  }
`;

export const GET_SESSION = gql`
  query getSession($id: ID!) {
    getSession(id: $id) {
      id
      leadType
      objective
      status
      buyer {
        email
        givenName
        familyName
      }
      seller {
        email
        givenName
        familyName
      }
      buyerPersona {
        name
        company
        industry
        timeInPosition
        education
        location
        jobTile
      }
      sellerNotes
    }
  }
`;

export const GET_TEAMS = gql`
  query getTeams($limit: Int, $nextToken: String) {
    getTeams(limit: $limit, nextToken: $nextToken) {
      teams {
        id
        name
        ownerId
        companyName
        createdAt
        memberCount
      }
      nextToken
    }
  }
`;
