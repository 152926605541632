import React, { useState } from 'react';
import { Button, Avatar, Link, Grid, Typography, Container, LinearProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import SignUpConfirmation from './SignupConfirmation';
import { useUser } from '../../../context/userContext';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';
import SnackbarAction from '../../common/SnackbarAction';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Values {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  team: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SignUp(): React.ReactElement {
  const classes = useStyles();
  const { signUp } = useUser();
  const [success, setSuccess] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  const decode = () => {
    if (query.get('key')) {
      try {
        return JSON.parse(decodeURIComponent(atob(query.get('key') || '')));
      } catch (error) {}
    }

    return {};
  };

  const handleSubmit = async (values: Values) => {
    try {
      if (!reCaptchaToken) {
        throw new Error('reCaptcha is required.');
      }

      await signUp?.(
        values.email,
        values.password,
        values.firstName,
        values.lastName,
        values.companyName,
        values.jobTitle,
        reCaptchaToken,
        values.team ? values.team : values.email,
        decode()['code'] ? 'true' : 'false',
        decode()['code'] || '',
      );
      setSuccess(true);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 10000, action: SnackbarAction });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {!success ? (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Formik
            initialValues={{
              email: decode()['email'] || '',
              password: '',
              firstName: '',
              lastName: '',
              companyName: decode()['company'] || '',
              jobTitle: '',
              team: decode()['team'] || '',
            }}
            validate={(values) => {
              const errors: Partial<Values> = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
              }

              if (!values.password) {
                errors.password = 'Required';
              } else if (values.password.length < 8) {
                errors.password = 'Password should be at least 8 characters';
              } else if (values.password.length > 99) {
                errors.password = 'Password should not be greater than 99 characters';
              } else if (values.password.toLowerCase() === values.password) {
                errors.password = 'Password should have at least one uppercase';
              } else if (values.password.toUpperCase() === values.password) {
                errors.password = 'Password should have at least one lowercase';
              } else if (!/\d/.test(values.password)) {
                errors.password = 'Password should have at least one number';
              } else if (!/[!@#$%^&]/.test(values.password)) {
                errors.password = 'Password should have at least one special character. Ex: !@#$%^&';
              } else if (/\s/g.test(values.password)) {
                errors.password = 'Password should not have whitespace';
              }

              if (!values.firstName) {
                errors.firstName = 'Required';
              }

              if (!values.lastName) {
                errors.lastName = 'Required';
              }

              if (!values.companyName) {
                errors.companyName = 'Required';
              }

              if (!values.jobTitle) {
                errors.jobTitle = 'Required';
              }

              return errors;
            }}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      label="Company"
                      name="companyName"
                      disabled={decode()['company'] ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      label="Team"
                      name="team"
                      disabled={decode()['team'] ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      label="Work Email"
                      name="email"
                      disabled={decode()['email'] ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} variant="outlined" fullWidth label="Job Title" name="jobTitle" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY || ''}
                      onChange={(val) => setReCaptchaToken(val)}
                    />
                  </Grid>
                </Grid>

                {isSubmitting && <LinearProgress />}
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  className={classes.submit}
                >
                  Sign Up
                </Button>
              </Form>
            )}
          </Formik>
          <Grid container justify="flex-end">
            <Grid item>
              <Link variant="body2" component={RouterLink} to="/auth/login">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </div>
      ) : (
        <SignUpConfirmation />
      )}
    </Container>
  );
}
